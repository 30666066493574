<template>
   <div>
      <div class="referral-main container">
         <div class="row">
            <b-button v-if="!isShowingCouponEdit" @click="$event.preventDefault(); startCreatingCoupon()" variant="primary">Create Another</b-button>
         </div>

         <div class="row" v-if="autoGeneratedCode">
            <span>Coupon code:</span>
            {{(newEntryIsCodeAutoGenerated ? autoGeneratedCode : newEntryCode)}}
         </div>


         <b-form @submit.prevent="onSubmit" v-if="isShowingCouponEdit">
            <h4 v-if="!isEditingExisting">New Coupon</h4>
            <h4 v-if="isEditingExisting">Edit Coupon</h4>

            <div class="form-check" v-if="!isShowingCouponEdit">
               <input
                  type="checkbox"
                  class="form-check-input"
                  id="isAutoGenCheckbox"
                  v-model="newEntryIsCodeAutoGenerated"
               >
               <label class="" for="isAutoGenCheckbox">Generate random coupon code</label>
            </div>

            <b-form-group
               id="input-group-0"
               label="Code:"
               label-for="input-0"
               v-if="!newEntryIsCodeAutoGenerated"
            >
               <b-form-input
                  v-if="isEditingExisting"
                  id="input-0"
                  v-model="newEntryCode"
                  placeholder="code"
                  readonly></b-form-input>
               <b-form-input
                  v-else
                  id="input-0"
                  v-model="newEntryCode"
                  placeholder="code"
                  required
                  style="width:15em"
                  ></b-form-input>
            </b-form-group>

            <b-form-group
               id="input-group-2"
               label="Admin note:"
               label-for="input-2"
            >
               <b-form-input
                  id="input-2"
                  v-model="newEntryAdminNote"
                  placeholder="admin note"
                  required
                  >
                  </b-form-input>
            </b-form-group>

            <b-form-group
               id="input-group-2"
               label="Expiration date (required for 100% off coupons):"
               label-for="input-2"
            >
               <b-form-input
                  id="input-2"
                  v-model="newEntryExpiration"
                  type="date"
                  placeholder="expiration"
                  style="width: 15em"
                  >
                  </b-form-input>
            </b-form-group>


            <b-form-group
               id="input-group-2"
               label="Max number of slices for credit (optional):"
               label-for="input-2"
            >
               <b-form-input
                  id="input-2"
                  v-model="newEntryMaxSlices"
                  placeholder="max slice count"
                  style="width: 4em"
                  v-if="!isEditingExisting">
               </b-form-input>
               <b-form-input
                  id="input-2"
                  v-model="newEntryMaxSlices"
                  placeholder="max slice count"
                  v-if="isEditingExisting"
                  style="width: 4em"
                  readonly>
               </b-form-input>
            </b-form-group>

            <b-form-group
               id="input-group-2"
               label="Percent discount (e.g. enter 25 for 1/4 off, or 100 for completely free):"
               label-for="input-2"
            >
               <b-form-input
                  v-if="!isEditingExisting"
                  id="input-2"
                  v-model="newEntryDiscountPercent"
                  placeholder="discount percent"
                  style="width: 4em"
                  >
               </b-form-input>
               <b-form-input
                  v-if="isEditingExisting"
                  id="input-2"
                  v-model="newEntryDiscountPercent"
                  placeholder="discount percent"
                  disabled
                  style="width: 4em;"
                  >
               </b-form-input>
               <span style="position: relative; left: 73px; top: -32px">%</span>
            </b-form-group>

            <div class="form-check">
               <input
                  type="checkbox"
                  class="form-check-input"
                  id="isMultiUseCheckbox"
                  v-model="newEntryIsMultiUse"
               >
               <label class="" for="isMultiUseCheckbox">Multi use</label>
            </div>

            <div class="form-check" v-if="isEditingExisting">
               <input
                  type="checkbox" 
                  class="form-check-input"
                  id="activeCheckboxDemo"
               >
               <label style="color: gray" class="" for="multiUseCheckboxDemo">Multi use - not allowed for discount equal to 100</label>
            </div>


            <b-list-group v-show="errors.length > 0">
               <b-list-group-item
               v-for="error in errors"
               :key="error"
               variant="danger"
               >{{ error }}</b-list-group-item
               >
            </b-list-group>

            <b-button @click="$event.preventDefault(); saveCoupon();" variant="primary" v-if="!isEditingExisting">Create</b-button>&nbsp;&nbsp;
            <b-button @click="$event.preventDefault(); saveCoupon();" variant="primary" v-if="isEditingExisting">Update</b-button>&nbsp;&nbsp;
            <b-button @click="$event.preventDefault(); cancelCoupon();" variant="secondary">Cancel</b-button>
         </b-form>

      </div>
      <div class="mt-3 mb-5">
         <hr/>
         <div class="form-check">
            <select v-model="couponFilter" @change="refreshCouponList()" class="form-control form-control-lg" id="coupon-filter">
               <option value="active">Show active promo codes</option>
               <option value="expired">Show expired promo codes</option>
            </select>
         </div>


         <ul>
            <table class="admin-table">
               <tr>
                  <th></th>
                  <th>Code</th>
                  <th>Created On</th>
                  <th>Expiration</th>
                  <th>Discount %</th>
                  <th>Created By</th>
                  <th>Single Use?</th>
                  <th>Uses</th>
                  <th>Max Slices</th>
                  <th>Memo</th>
                  <th></th>
               </tr>
               <tr v-for="value in coupons" v-bind:key="value['code']">
                  <td>
                     <a href="#" @click="editCouponByCode(value)">Edit</a>
                  </td>
                  <td>
                     {{value["code"]}}
                  </td>

                  <td>
                     {{value["created"]}}
                  </td>

                  <td>
                     {{value["expiration"]}}
                  </td>

                  <td>
                     {{value["discount_percent"]}}
                  </td>

                  <td>
                     {{value["created_by_user"]}}
                  </td>

                  <td>
                     {{value["is_single_use"]}}
                  </td>

                  <td>
                     {{value["usage_count"]}}
                  </td>

                  <td>
                     {{value["max_slice_count"]}}
                  </td>

                  <td>
                     {{value["admin_message"]}}
                  </td>

                  <td>
                     <a href="#" @click="copyLink(value)">Copy Link</a>
                  </td>                  
               </tr>
            </table>
         </ul>
      </div>
   </div>
</template>

<script>

export default {
   name: "Coupons",
   data() {
      return {
         autoGeneratedCode: "",
         coupons: [],
         isShowingCouponEdit: false,
         isEditingExisting: false,
         newEntryCode: "",
         newEntryIsCodeAutoGenerated: true,
         newEntryIsMultiUse: true,
         newEntryDiscountPercent: 10,
         newEntryMaxSlices: 0,
         newEntryExpiration: "",
         newEntryAdminNote: "",
         autoGeneratedCoupon: "",
         errors: [],
         couponFilter: 'active',
      }
   },
   mounted() {
      this.refreshCouponList();
   },
   methods: {
      startCreatingCoupon() {
         this.isShowingCouponEdit = true;
         this.autoGeneratedCode = "";
         this.newEntryIsCodeAutoGenerated = false;

         // Our alphabet is 29 characters aka <32 or <(2**5) aka <5 bits of entropy per character.
         // Each random byte gives us 8 random bits, so it's more than enough.
         let couponAlphabet = "ABCDEFGHJKLMNPQRTUVWXY3456789";

         let couponLength = 12;
         let randomValues = new Uint8Array(couponLength);
         window.crypto.getRandomValues(randomValues)

         let result = "";
         for (let i = 0; i < couponLength; ++i) {
            result += couponAlphabet[randomValues[i] % couponAlphabet.length];
         }
         this.newEntryCode = result;
      },

      saveCoupon() {
         this.errors = [];

         let expiration = null;
         try {
            let is100PctOff = parseInt(this.newEntryDiscountPercent) >= 100;
            if (this.newEntryExpiration)
            {
               expiration = Date.parse(this.newEntryExpiration);
               if (is100PctOff) {
                  let dateDiff = expiration - (new Date()).getTime();
                  let daysDiff = dateDiff / 1000 / 60 / 60 / 24;
                  if (daysDiff > 31)
                  {
                     this.errors.push("For 100% off promo codes, your expiration can be no more than 30 days from now");
                  }
               }
            }
            else if (is100PctOff)
            {
               this.errors.push("Promo codes with 100% off must have an expiration date within 30 days");
            }
         } catch (exc) {
            this.errors.push("Invalid expiration date");
            throw(exc);
         }
         if (this.errors.length) {
            return;
         }

         if (this.isEditingExisting) {
            let couponPostData = {
               code: this.newEntryCode,
               expiration: expiration,
               note: this.newEntryAdminNote,
               isSingleUse: !this.newEntryIsMultiUse
            };

            this.axios
               .post("/api/admin-update-coupon", couponPostData, { withCredentials: true })
               .then((response) => {
                  console.log(response);
                  console.log(response.data.coupons);
                  this.refreshCouponList();
                  this.isShowingCouponEdit = false;
               })
               .catch((error) => {
                  console.error(error);
                  this.errors.push("Failed to save coupon; please check your inputs and try again.");
               });
         }
         else {
            let couponPostData = {
               code: (this.newEntryIsCodeAutoGenerated ? this.autoGeneratedCode : this.newEntryCode),
               expiration: expiration,
               maxSlices: this.newEntryMaxSlices,
               percentDiscount: this.newEntryDiscountPercent,
               note: this.newEntryAdminNote,
               isSingleUse: !this.newEntryIsMultiUse
            };

            this.axios
               .post("/api/admin-create-coupon", couponPostData, { withCredentials: true })
               .then((response) => {
                  console.log(response);
                  console.log(response.data.coupons);
                  this.refreshCouponList();
                  this.isShowingCouponEdit = false;
               })
               .catch((error) => {
                  console.error(error);
                  this.errors.push("Failed to save coupon; please check your inputs and try again.");
               });
         }
      },
      editCouponByCode(coupon) {
         this.newEntryCode = coupon.code;

         this.newEntryIsCodeAutoGenerated = false;
         this.newEntryIsMultiUse = !coupon.is_single_use;
         this.newEntryDiscountPercent = coupon.discount_percent;
         this.newEntryMaxSlices = coupon.max_slice_count;
         this.newEntryExpiration = coupon.expiration;
         this.newEntryAdminNote = coupon.admin_message;

         this.isEditingExisting = true;
         this.isShowingCouponEdit = true;

         console.log(coupon);
      },
      cancelCoupon() {
         this.isShowingCouponEdit = false;
         this.isEditingExisting = false;
      },
      copyLink(code) {
         let shareUrl = "https://" + window.location.host + "/?r=" + code['code'];
         navigator.clipboard.writeText(shareUrl);
      },
      refreshCouponList() {
         this.axios
         .get("/api/get-coupon-admin-data", { withCredentials: true })
         .then((response) => {
            console.log(response);
            console.log(response.data.coupons);
            let isShowingExpired = this.couponFilter === 'expired';
            this.coupons = response.data.coupons.filter(function (entry) {
               let isThisEntryExpired = (entry.expiration != null && ((new Date(entry.expiration)).getTime() < (new Date()).getTime()));
               return isShowingExpired == isThisEntryExpired;
            });
         })
         .catch((error) => console.error(error));
      }
   }
}
</script>
